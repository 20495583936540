<template>
  <SedeDesdeHasta
    titulo="Reportes de Inventario - Compras"
    :servicioGetReporte="servicioGetReporte"
    :servicioGetReportePdf="servicioGetReportePdf"
    :componenteListado="componenteListado"
  />
</template>
  
<script>
import SedeDesdeHasta from "../components/SedeDesdeHasta.vue";
import ReporteInventarioService from "../services";

export default {
  name: "ReporteInventarioComprasLayout",
  components: {
    SedeDesdeHasta,
  },
  data() {
    return {
      componenteListado: "ListadoComprasVentas",
    };
  },
  methods: {
    servicioGetReporte: ReporteInventarioService.getReporteInventarioCompras,
    servicioGetReportePdf:
      ReporteInventarioService.getReporteInventarioComprasPdf,
  },
};
</script>
  